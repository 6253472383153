<script setup lang="ts">
import { ref } from "vue";
import { formatHyperLink } from "../../utils/format";

const {
  title,
  text,
  expandDefault = false,
} = defineProps<{
  title: string;
  text: string | undefined;
  expandDefault?: boolean;
}>();
let isOpen = ref<boolean>(expandDefault);

function onClick() {
  isOpen.value = !isOpen.value;
}
</script>
<template>
  <div class="border-grey-100 border-b">
    <div
      v-if="!!text"
      class="flex cursor-pointer items-center justify-between"
      @click="onClick"
    >
      <div class="font-f37lineca py-2">{{ title }}</div>

      <svg
        v-if="isOpen"
        class="w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
      </svg>

      <svg
        v-else
        class="w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 4.5v15m7.5-7.5h-15"
        />
      </svg>
    </div>
    <div
      v-show="isOpen"
      class="v-html mb-3 text-xs"
      v-html="formatHyperLink(text)"
    ></div>
  </div>
</template>

<style scoped>
.v-html :deep(a) {
  color: #2563eb;
  text-decoration-line: underline;
  text-underline-offset: 2px;
  text-decoration-color: #2563eb;
}
</style>
