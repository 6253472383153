<script setup lang="ts">
import { useProductStore } from "../../stores/product";

const props = defineProps<{
  category: string;
}>();

const productStore = useProductStore();

const emit = defineEmits(["categoryFilterSelected"]);

function onLineClick(categoryName: string) {
  emit("categoryFilterSelected", categoryName);
}
</script>

<template>
  <div class="border-b border-grey-300 py-4">
    <div class="flex" @click="onLineClick($props.category)">
      <span class="flex-1">
        {{ props.category }}
      </span>
      <span class="flex-none">
        <button type="button" class="text-grey-700">
          <img src="/static/img/left-chevron.svg" />
        </button>
      </span>
    </div>
  </div>
</template>
