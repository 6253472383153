/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptedTerms } from "../models/AcceptedTerms";
import type { ForexBaseTradeDetails } from "../models/ForexBaseTradeDetails";
import type { ForexCompletedTradeDetails } from "../models/ForexCompletedTradeDetails";
import type { ForexEstimatedTradeDetails } from "../models/ForexEstimatedTradeDetails";
import type { ForexTermsAccept } from "../models/ForexTermsAccept";
import type { ForexTradeRequestDetails } from "../models/ForexTradeRequestDetails";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class FxService {
  /**
   * Check whether user has accepted fx terms
   * @returns AcceptedTerms Successful response
   * @throws ApiError
   */
  public static getTermsFx(): CancelablePromise<AcceptedTerms> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/terms/fx",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get trade detail by tradeId
   * @param tradeId ID of the trade to get
   * @returns any Successful response
   * @throws ApiError
   */
  public static getForexTrades(
    tradeId: string
  ): CancelablePromise<ForexBaseTradeDetails & ForexCompletedTradeDetails> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/forex/trades/{tradeId}",
      path: {
        tradeId: tradeId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Server error`,
      },
    });
  }

  /**
   * Confirm a forex trade
   * @param xIdempotencyKey
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static postForexTrade(
    xIdempotencyKey?: string,
    requestBody?: ForexTradeRequestDetails & ForexTermsAccept
  ): CancelablePromise<ForexBaseTradeDetails & ForexCompletedTradeDetails> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/forex/trade",
      headers: {
        "X-Idempotency-Key": xIdempotencyKey,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Estimate rate for a forex trade
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static postForexEstimate(
    requestBody?: ForexTradeRequestDetails
  ): CancelablePromise<ForexBaseTradeDetails & ForexEstimatedTradeDetails> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/forex/estimate",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Server error`,
      },
    });
  }

  public static getNccCurrencies(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/config/currency/fx/exotic",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Server error`,
      },
    });
  }
}
