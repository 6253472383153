<script setup lang="ts">
import {
  DenominationTypeEnum,
  SelectLinkProduct,
} from "../../api.generated/scion";
import { getCurrencySymbol } from "../../utils/stores/utils";
import { useLinkDetailsStore } from "../../stores/linkDetails";
import { useProductStore } from "../../stores/product";
import { onMounted, ref, computed } from "vue";
import { validateAmountForProductAndRemainingBalance } from "../../utils/validators";
import { useBasketStore } from "../../stores/basket";
import { formatCurrencyValue } from "../../utils/format";

const { product } = defineProps<{
  product: SelectLinkProduct;
}>();
const emit = defineEmits(["amountChanged", "isValidAmountSelected"]);

const linkDetailsStore = useLinkDetailsStore();
const productStore = useProductStore();
const basketStore = useBasketStore();

const currency = getCurrencySymbol(linkDetailsStore, productStore);
const remainingCredit =
  linkDetailsStore.currentCredit - basketStore.getCartTotalAmount;
const roundMinimumValue = Math.ceil(product.minimumValue);
let amountSelected = ref<number>(roundMinimumValue);
let inputErrorMsg = ref<string>("");

const availableDenominations = computed(() => {
  return product.availableDenominations?.filter(
    (denom) => Number(denom) <= remainingCredit
  );
});

function findLowestAvailableDenominationsGreaterMinimumValue(): number {
  let denominationOptionIndex = -1;
  const array = product.availableDenominations || [];
  if (DenominationTypeEnum.FIXED === product.denominationType) {
    let nextGreaterProductMinimumValue = Number.MAX_SAFE_INTEGER;
    array.forEach((value, idx) => {
      const result = Number(value) - roundMinimumValue;
      if (result >= 0 && result < nextGreaterProductMinimumValue) {
        nextGreaterProductMinimumValue = result;
        denominationOptionIndex = idx;
      }
    });
  }
  return Number(array[denominationOptionIndex]) || roundMinimumValue;
}

onMounted(async () => {
  validateAmount(findLowestAvailableDenominationsGreaterMinimumValue());
});

function remainingBalanceAsString() {
  return formatCurrencyValue(linkDetailsStore.currency, remainingCredit);
}

function amountChanged(newAmount: number) {
  inputErrorMsg.value = "";
  emit("amountChanged", newAmount);
  amountSelected.value = newAmount;
  isValidAmountSelected(true);
}

function fixedAmountChanged(newAmount: number) {
  inputErrorMsg.value = "";
  validateAmount(newAmount);
}

function isValidAmountSelected(isValid: boolean) {
  emit("isValidAmountSelected", isValid);
}

function getAmountInputErrorMessage(amount: number): string {
  return validateAmountForProductAndRemainingBalance(
    amount,
    product,
    +linkDetailsStore.currentCredit - +basketStore.getCartTotalAmount
  );
}

function validateAmount(newAmount: number) {
  let errorMessage = getAmountInputErrorMessage(newAmount);
  if (!!errorMessage) {
    inputErrorMsg.value = errorMessage;
    isValidAmountSelected(false);
    return;
  }
  inputErrorMsg.value = "";
  amountChanged(newAmount);
}

function onAmountInputChanged(event: Event) {
  let newAmount = 0;
  if (event.target != null) {
    newAmount = parseFloat((event.target as HTMLInputElement).value) || 0;
    validateAmount(newAmount);
  }
}

function formatDominationWithCurrency(domination: string) {
  return `${currency}${domination}`;
}

function isOpenDenomination(product: SelectLinkProduct) {
  return product.denominationType === DenominationTypeEnum.OPEN;
}

function selectedAmountStyle(denomination: string) {
  if (amountSelected.value === +denomination) {
    return "border-black";
  }
}

function inputErrorStyle() {
  if (inputErrorMsg.value !== "") {
    return "border-red focus:border-red";
  } else {
    return "border-gray-100 focus:border-black";
  }
}
</script>

<template>
  <div>
    <div v-if="isOpenDenomination(product)" class="flex flex-col">
      <label
        class="form-control-label relative left-[1.1rem] top-[1.7rem] w-fit text-[0.8em]"
        for="amountInputField"
      >
        Enter amount
      </label>
      <input
        id="amountInputField"
        v-model="amountSelected"
        type="number"
        inputmode="numeric"
        step="1"
        @input="onAmountInputChanged($event)"
        :min="roundMinimumValue"
        :max="product.maximumValue"
        :class="[
          'apparence-none rounded-lg border-2 bg-gray-100 px-3.5 pb-2 pt-6 outline-none',
          inputErrorStyle(),
        ]"
      />
    </div>
    <div v-if="!!inputErrorMsg" class="ml-[0.1rem] mt-1 text-sm text-red">
      {{ inputErrorMsg }}
    </div>

    <p class="mt-4 text-sm">
      Current balance: {{ remainingBalanceAsString() }}
    </p>

    <div
      v-if="!!availableDenominations"
      class="mt-4 flex w-0 min-w-full flex-row overflow-x-auto"
    >
      <div
        v-for="domination in availableDenominations"
        @click="fixedAmountChanged(+domination)"
        :class="[
          'border-grey-200 m-1 min-w-12 cursor-pointer rounded-xl border-4 bg-gray-200 px-4 py-2 text-sm font-semibold',
          selectedAmountStyle(domination),
        ]"
      >
        {{ formatDominationWithCurrency(domination) }}
      </div>
    </div>
  </div>
</template>
