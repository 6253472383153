<script setup lang="ts">
import { RemoveAssetModal, Product, Spinner, Advert } from "../index";
import { BasketItem, useBasketStore } from "../../stores/basket";
import { computed, ref, watch } from "vue";
import { useLinkDetailsStore } from "../../stores";
import { formatCurrencyValue } from "../../utils/format";
import { makeMultipleSelectLinkOrders } from "../../services/selectLink";
import { useIdempotencyKeyStore } from "../../stores/idempotencyKey";
import { useTokenStore } from "../../stores/token";
import {
  SelectLinkOrderItemResult,
  SelectLinkOrderResponse,
} from "../../api.generated/scion";
import { getPartialSuccessErrorMessage } from "../../utils/purchasingError";
import { event as gaEvent } from "vue-gtag";
import { v4 as uuidv4 } from "uuid";

const emit = defineEmits(["clickedAddMoreAssets", "purchaseComplete"]);

const linkDetailsStore = useLinkDetailsStore();
const basketStore = useBasketStore();
const idempotencyKeyStore = useIdempotencyKeyStore();
const tokenStore = useTokenStore();

let isGenericError = ref<boolean>(false);
let isLoading = ref<boolean>(false);

const walletBalance = ref<number>(linkDetailsStore.currentCredit);

// Watch for changes in linkDetailsStore.currentCredit
watch(
  () => linkDetailsStore.currentCredit,
  (newCredit) => {
    walletBalance.value = newCredit;
  }
);

const selectedBasketItem = ref<BasketItem | null>();

const newBalance = computed(() => {
  return walletBalance.value - basketStore.getCartTotalAmount;
});

function removeAssetFromBasket(id: string) {
  selectedBasketItem.value = null;
  basketStore.removeItem(id);

  if (basketStore.isCartEmpty) {
    isGenericError.value = false;
  }
}

function onClickRemove(basketItem: BasketItem) {
  if (!basketStore.isOrderProcessing) {
    selectedBasketItem.value = basketItem;
  }
}

function closeRemoveAssetModal() {
  selectedBasketItem.value = null;
}

function onClickAddMoreAssets() {
  if (!basketStore.isOrderProcessing) {
    emit("clickedAddMoreAssets");
  }
}

async function onClickConfirm() {
  try {
    basketStore.setIsOrderProcessing(true);
    isLoading.value = true;

    const idempotencyKey = idempotencyKeyStore.getKey();
    const response = await makeMultipleSelectLinkOrders(
      idempotencyKey,
      tokenStore.token,
      basketStore.getItems
    );

    gaEvent("purchase", {
      hostname: window.location.hostname,
      transaction_id: uuidv4(),
      value: basketStore.getCartTotalAmount,
      tax: 0,
      shipping: 0,
      currency: linkDetailsStore.currency,
      items: basketStore.getItems.map((item) => ({
        id: item.product.code,
        name: item.product.name,
        price: item.amount,
        quantity: 1,
        item_category: item.source as string,
      })),
    });

    handlePartialSuccess(response);
    emit("purchaseComplete", basketStore.getNumberOfItems);
    basketStore.removeAllItems();
    isGenericError.value = false;
  } catch (e) {
    console.error(e);
    isGenericError.value = true;
  } finally {
    isLoading.value = false;
    basketStore.setIsOrderProcessing(false);
  }
}

function handlePartialSuccess(response: SelectLinkOrderResponse) {
  if (response.status === SelectLinkOrderItemResult.ERROR) {
    const errorMessage = getPartialSuccessErrorMessage(response.message);
    basketStore.setPurchasingErrorMessage(errorMessage);
  }
}

function confirmButtonStyle(primaryColour: string) {
  const baseStyle =
    "justify-center inline-flex drop-shadow-lg w-full p-4 text-white border rounded-xl transition duration-100 ease-in-out focus:ring-2 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:border-opacity-50 disabled:cursor-not-allowed text-sm";
  if (!primaryColour) {
    return (
      baseStyle +
      " " +
      "border-black bg-black enabled:hover:bg-gray-800 focus:border-blue-500 focus:ring-blue-500"
    );
  } else {
    return baseStyle + " " + "customisations";
  }
}
</script>

<template>
  <div>
    <div v-if="basketStore.isCartEmpty">
      <div
        class="mb-4 mt-2 flex flex-col items-center justify-center rounded-2xl bg-grey-300 px-8 py-6 text-center md:my-6 md:bg-white lg:px-12"
      >
        <div class="mb-6 grid grid-cols-4 gap-2 md:max-[1000px]:grid-cols-2">
          <img src="/static/img/cart-green.svg" />
          <img src="/static/img/calendar-green.svg" />
          <img src="/static/img/gift-green.svg" />
          <img src="/static/img/credit-card-green.svg" />
        </div>
        <h3 class="font-f37lineca mb-2">You haven't selected anything</h3>
        <p class="text-sm md:max-[1000px]:hidden">
          Start adding gift cards, subscriptions, or donations from the list.
        </p>
      </div>
    </div>

    <transition-group name="basket-item" tag="ul">
      <li
        v-for="basketItem in basketStore.getItems"
        :key="basketItem.id"
        class="my-4 flex h-auto flex-row rounded-xl bg-grey-300 p-3 pb-2 md:bg-grey-900"
      >
        <Product
          class="pointer-events-none mb-2 min-w-24 flex-30 shrink-0 lg:mb-0"
          :key="basketItem.id"
          :product="basketItem.product"
        >
        </Product>
        <span class="min-w-0 flex-70 pl-4">
          <div
            class="flex min-w-0 text-sm font-semibold md:max-[1000px]:flex-col"
          >
            <span class="mr-2 flex-1 overflow-hidden text-ellipsis">
              {{ basketItem.product.name }}
            </span>
            <span class="">
              {{
                formatCurrencyValue(
                  basketItem.product.currency,
                  basketItem.amount
                )
              }}
            </span>
          </div>
          <div
            class="cursor-pointer underline hover:no-underline"
            @click="onClickRemove(basketItem)"
          >
            Remove
          </div>
        </span>
      </li>
    </transition-group>

    <div
      v-if="newBalance > 0 && !basketStore.isCartEmpty"
      class="my-4 h-auto rounded-xl bg-green px-4 py-3 text-xs"
    >
      <p class="mb-1 font-semibold">
        You have
        {{ formatCurrencyValue(linkDetailsStore.currency, newBalance) }} left to
        redeem
      </p>
      <p class="mb-1">Would you like to add more assets?</p>
      <div
        class="cursor-pointer underline hover:no-underline md:hidden"
        @click="onClickAddMoreAssets"
      >
        Add more assets
      </div>
    </div>

    <div class="text-xs">
      <div class="flex items-center border-b py-3">
        <span class="flex-1"> Wallet balance </span>
        <span class="shrink-0"
          >{{ formatCurrencyValue(linkDetailsStore.currency, walletBalance) }}
        </span>
      </div>

      <div class="flex items-center border-b py-3">
        <span class="flex-1"> Selected cards </span>
        <span class="shrink-0">
          {{
            formatCurrencyValue(
              linkDetailsStore.currency,
              basketStore.getCartTotalAmount
            )
          }}
        </span>
      </div>

      <div
        v-if="newBalance !== walletBalance"
        class="flex items-center border-b py-3"
      >
        <span class="flex-1"> New balance </span>
        <span class="shrink-0">
          {{ formatCurrencyValue(linkDetailsStore.currency, newBalance) }}
        </span>
      </div>
    </div>

    <div
      v-if="!basketStore.isCartEmpty"
      class="my-4 h-auto rounded-xl bg-grey-300 px-4 py-3 text-xs md:bg-zinc-200"
    >
      <p class="mb-1 font-semibold">Important</p>
      <p>You will not be able to change your selection after you confirm.</p>
    </div>

    <div
      v-if="!basketStore.isCartEmpty && isGenericError"
      class="my-4 h-auto rounded-xl bg-error-200 px-4 py-3 text-xs"
    >
      <p class="mb-1 font-semibold">We couldn't generate your assets</p>
      <p>
        Please try again shortly. Otherwise please contact support at:
        ordersupport@runa.io
      </p>
    </div>

    <button
      v-if="!basketStore.isCartEmpty"
      :class="confirmButtonStyle(linkDetailsStore.customisation.primaryColour)"
      :style="{ '--bg-color': linkDetailsStore.customisation.primaryColour }"
      @click="onClickConfirm"
      :disabled="isLoading"
    >
      <div v-if="isLoading">
        <Spinner />
      </div>

      <div v-else>Confirm selection</div>
    </button>

    <div
      v-if="!basketStore.isCartEmpty && linkDetailsStore.hasSidebarAdvert"
      class="mt-4"
    >
      <advert
        :image="linkDetailsStore.advert.sidebarImageUrl"
        :target="linkDetailsStore.advert.sidebarLink"
      />
    </div>

    <RemoveAssetModal
      v-if="!!selectedBasketItem"
      :itemName="selectedBasketItem.product.name"
      :itemId="selectedBasketItem.id"
      @closeRemoveAssetModal="closeRemoveAssetModal"
      @removeAssetFromBasket="removeAssetFromBasket"
    />
  </div>
</template>

<style scoped>
.basket-item-enter-active {
  animation: slide-in 0.3s;
}
.basket-item-leave-active {
  animation: slide-in 0.3s reverse;
}
.basket-item-enter-active:only-child {
  animation: none;
}
.basket-item-leave-active:only-child {
  animation: none;
}
.basket-item-enter-from,
.basket-item-leave-to {
  opacity: 0;
  transform: translateX(-25%);
}
.customisations {
  background-color: var(--bg-color);
  border: 1px solid var(--bg-color);
}

@keyframes slide-in {
  from {
    transform: translateX(-25%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}
</style>
