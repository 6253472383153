<script setup lang="ts">
import { useLinkDetailsStore } from "../../stores/linkDetails";
import { TwoSelectField } from "@wegift/two-components";
import {
  getRemainingDuration,
  getCurrentCredit,
  getCurrentCreditAsFX,
} from "../../utils/stores/utils";
import { formatDateWithUserLocale } from "../../utils/format";
import { computed, ref } from "vue";
import { CurrencyEnum } from "../../api.generated/scion";

const linkDetailsStore = useLinkDetailsStore();

const expiryDateString = linkDetailsStore.expiryDate as string;

const currencySelectionOptions = computed(() => {
  return [
    {
      value: CurrencyEnum.USD,
      label: "🇺🇸 United States (USD)",
    },
    {
      value: CurrencyEnum.EUR,
      label: "🇦🇹 Austria (EUR)",
    },
    {
      value: CurrencyEnum.GBP,
      label: "🇬🇧 United Kingdom (GBP)",
    },
    {
      value: CurrencyEnum.CAD,
      label: "🇨🇦 Canada (CAD)",
    },
  ];
});

const emit = defineEmits<{
  (e: "currencyChanged", currencyCode: CurrencyEnum): void;
}>();

const newCurrency = ref("");

const newBalance = ref();

const displayCurrencyBalance = async () => {
  if (linkDetailsStore.currency != linkDetailsStore.baseCurrency) {
    const r = await getCurrentCreditAsFX(
      linkDetailsStore,
      linkDetailsStore.currency.toString()
    );
    return r;
  } else {
    return getCurrentCredit(linkDetailsStore);
  }
};

const getIconUrl = (currencyCode: string) => {
  return new URL(
    `../../../../assets/icons/${currencyCode}.svg`,
    import.meta.url
  ).href;
};

const currencyUpdate = async (toCurrency: any) => {
  linkDetailsStore.setCurrency(toCurrency);
  emit("currencyChanged", toCurrency);
  newCurrency.value = toCurrency;
  newBalance.value = await displayCurrencyBalance();
};

function getBalance() {
  if (!newBalance.value) {
    return getCurrentCredit(linkDetailsStore);
  }
  return newBalance.value;
}
</script>

<template>
  <div>
    <div
      v-if="!linkDetailsStore.logoUrl.endsWith('placeholder.png')"
      class="mb-6"
    >
      <img :src="linkDetailsStore.logoUrl" alt="Logo" />
    </div>
    <div class="flex items-center justify-between">
      <div>
        <div class="font-f37lineca">Balance</div>
        <div class="font-f37lineca text-[42px] leading-[48px] tracking-tight">
          {{ getBalance() }}
        </div>
        <div class="text-xs font-extralight">
          <span v-if="!linkDetailsStore.isLinkExpired">
            Expires in {{ getRemainingDuration(expiryDateString) }}
          </span>
          <span v-else>
            Your balance expired on
            {{ formatDateWithUserLocale(expiryDateString) }}
          </span>
        </div>
      </div>
      <div class="ml-4">
        <TwoSelectField
          data-testid="fx-currency"
          placeholder="Select another currency"
          :options="currencySelectionOptions"
          :model-value="linkDetailsStore.currency"
          @change="currencyUpdate"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.text-balance {
  font-size: 42px;
}
</style>
