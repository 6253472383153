<script setup lang="ts">
import { ref, computed } from "vue";
import { Modal } from "..";
import { useLinkDetailsStore } from "../../stores";

defineProps<{
  title: string;
}>();

const linkDetailsStore = useLinkDetailsStore();

const hasRecommendedAssets = computed(() => {
  return !!(
    import.meta.env[
      `VITE_RECOMMENDED_ASSET_CODES_${linkDetailsStore.currency}`
    ] || ""
  );
});

const sortOptions = computed(() => {
  const currencySpecificSortOptions: [[string, string]] | [] =
    hasRecommendedAssets.value ? [["Recommended", "recommended"]] : [];

  return new Map([
    ...currencySpecificSortOptions,
    ["A-Z", "asc"],
    ["Z-A", "dsc"],
  ]);
});

const isModalOpen = ref<boolean>(false);
const selectedOption = ref<string>(sortOptions.value.keys().next().value);

const emit = defineEmits(["sortSelected"]);

function toggleModal() {
  isModalOpen.value = !isModalOpen.value;
}

function selectOption(option: string) {
  selectedOption.value = option;
  toggleModal();
  emit("sortSelected", sortOptions.value.get(option));
}
</script>

<template>
  <div>
    <button
      @click="toggleModal"
      class="flex w-full items-center justify-center rounded-lg bg-grey-300 px-4 pb-2 pt-3"
    >
      <img src="/static/img/down-arrow.svg" width="24" height="24" />

      <span class="truncate text-ellipsis px-2 font-semibold">
        Sort by: {{ selectedOption }}
      </span>
    </button>

    <Modal
      v-if="isModalOpen"
      @onCloseClick="toggleModal"
      :title="title"
      overlay
      closable
      size="large"
    >
      <template #body>
        <ul>
          <li
            v-for="option in sortOptions.keys()"
            :key="option"
            @click="selectOption(option)"
            class="flex cursor-pointer items-center border-b border-grey-300 py-4 last:border-0"
            :data-option="option"
          >
            <span class="flex-1">
              {{ option }}
            </span>

            <div v-if="option === selectedOption">
              <img src="/static/img/tick.svg" />
            </div>
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>
