<script setup lang="ts">
import { useLinkDetailsStore, useBasketStore } from "../../stores";

const linkDetailsStore = useLinkDetailsStore();
const basketStore = useBasketStore();

const emit = defineEmits(["onBasketFABClick"]);

function onBasketFABClick() {
  emit("onBasketFABClick");
}
</script>

<template>
  <div
    class="flex h-[72px] content-center items-center justify-around rounded-full bg-white/[.79] p-4 shadow-2xl drop-shadow-2xl backdrop-blur-xl"
  >
    <div
      class="flex h-8 w-8 items-center justify-center rounded-full bg-white p-2"
    >
      <div class="font-semibold">
        {{ basketStore.getNumberOfItems }}
      </div>
    </div>
    <div class="px-4 text-center font-bold">Basket</div>
    <div
      class="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-white p-2"
      @click="onBasketFABClick"
    >
      <img src="/static/img/up-chevron.svg" />
    </div>
  </div>
</template>
